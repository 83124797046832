.continue {
  background: $white;
  left: 0;
  position: fixed;
  text-align: center;
  transition: .3s bottom ease;
  display: flex;
  bottom: -200px;
  flex-direction: column;
  width: 100vw;
  z-index: 7;
  .js-show-contiune & {
    bottom: 0;
  }
  .btn {
    display: block;
    margin: $global-padding;
    flex-grow: 1;
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
}
