.label {
  &:before {
    content: '';
    display: inline-block;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 1rem;
    margin-bottom: 1px;
    margin-right: $global-margin / 2;
  }
  &-success {
    &:before {
      background-color: $green;
    }
  }
  &-danger {
    &:before {
      background-color: $red;
    }
  }
  &-default {
    &:before {
      background-color: $gray;
    }
  }
}

.infobox {
  border-width: 1px;
  border-radius: 3px;
  border-style: solid;
  display: inline-block;
  font-size: $defaultFontSizeSmall;
  padding: 0 $global-padding * .5;
  &--danger {
    background-color: #F8CCCC;
    border-color: #F08989;
    color: #DD0000;
  }
  &--info {
    background-color: #CCDCE9;
    border-color: #78A3C4;
    color: #00538F;
  }
  &--success {
    background-color: #D1EECC;
    border-color: #91D686;
    color: #18AA00;
  }
  &--warning {
    background-color: #FFFCB1;
    border-color: #F3EA00;
    color: #908B00;
    padding: 5px;
  }
}
