.footer {
  background-color: $body-color;
  clip-path: polygon(0 calc(100vw / 9), 100% 0, 100% 100%, 0 100%);
  margin-top: $global-margin * 8;
  padding: $global-padding * 8 0;
  position: relative;
  z-index: 4;
  &__menus {
    @include media-breakpoint-down(xs) {
      text-align: center;
      margin-top: $global-margin * 2;
    }
    .menu__item {
      & > a {
        font-weight: 600;
      }
    }
    &__social {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      @include media-breakpoint-down(sm) {
        margin-top: $global-margin * 3;
      }
      &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }
    }
  }
  .center-on-small {
    @include media-breakpoint-down(sm) {
      text-align: center !important;
      padding: 0;
    }
  }
  .custom-padding {
    padding-right: 0;
    @include media-breakpoint-down(sm) {
      padding-right: 15px;
    }
  }
  .custom-margin {
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
    }
  }
  &__copy {
    padding: $global-padding * 2 0 $global-padding * 3;
    &-text {
      text-align: right;
      color: #AAAAAA;
      font-size: 14px;
      margin-top: 12px;
      a {
        color: white;
      }
    }
  }
  &__logo {
    margin-bottom: $global-margin;
    svg {
      height: 16px;
      width: auto;
    }
  }
  hr {
    margin-top: 0;
  }
  ul {
    list-style: none;
    padding-left: 0;
    a {
      color: $light-gray;
      line-height: 2;
      font-weight: 300;
    }
  }
  .footer-label {
    color: $generic;
    font-weight: bold;
    text-transform: uppercase;
    font-size: $defaultFontSizeSmall;
  }
  .footer-social {
    overflow: auto;
    margin-bottom: -16px;
    a {
      display: inline-block;
      margin: 0 16px 16px 0;
    }
  }
  a {
    transition: 0.3s;
    &:hover {
      text-decoration: none;
      opacity: 0.6;
    }
    &.social-footer {
      background-color: $dark-blue;
      border-radius: 100%;
      display: inline-block;
      height: 36px;
      margin-bottom: $global-margin;
      margin-right: $global-margin;
      width: 36px;
      svg {
        height: 16px;
        margin-top: 8px;
        width: 100%;
      }
    }
  }

  /* based on
   * https://gitlab.com/shiftcrypto/website_design/-/blob/15ed2ac/css/style.css#L2234-2242
   */
  .payment-icon {
    margin: 0;
    padding: 0 5px 8px 0;
    display: inline-block;

    img {
      height: 25px;
      width: auto;
    }
  }
}
