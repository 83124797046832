.banner {
  display: block;
  padding: 0.7em 1em;
  position: relative;
  width: 100%;
  color: white;
}
.banner .banner-content {
  text-align: center;
  padding: 0em 1em;
  flex-basis: 100%;
  max-width: 100%;
}
.banner .banner-content p {
  font-weight: bold;
  color: white;
  text-align: center;
  margin-bottom: 0;
}
.banner .banner-content a {
  color: white;
  text-decoration: underline;
}
.banner .banner-content a:hover {
  color: #d3d3d3;
}
.banner .close-banner {
  position: absolute;
  right: .75em;
  top: .7em;
}
.banner .close-banner button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font: inherit;
  line-height: 1;
  outline: inherit;
  padding: 0;
  width: 1.5em;
}
