.order-details {
  background-color: $white;
  padding: 1rem;
  &__header {
    margin: $global-margin 0 $global-margin * 2;
  }
  &__addresses {
    margin-top: $global-margin * 2;
  }
  &__product {
    .col-3 {
      margin-top: $global-margin / 2;
    }
    &__description {
      a {
        transition: 0.3s;
        &:hover {
          color: $skull-gray;
        }
      }
      img {
        vertical-align: top;
        margin-right: $global-margin;
      }
    }
  }
  &__total {
    padding-top: $global-padding;
    color: black;
    font-weight: 400;
  }
  &__last-row {
    border-bottom-color: $skull-gray;
  }
}
