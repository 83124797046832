#product-list-image {
  background-position: top;
  background-repeat: repeat-x;
  background-size: cover;
  @include media-breakpoint-down(sm) {
    padding: 17 * $global-padding 0 0;
    margin-top: -1 * $global-margin;
  }
  @include media-breakpoint-up(md) {
    padding: 20 * $global-padding 0 0;
  }
}

.list-products,
.list-category-products,
.list-accessories,
.list-recommended-products {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.list-category-products,
.list-accessories,
.list-recommended-products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-item {
  background-color: $white;
  color: $body-color;
  margin-bottom: $global-padding;
  width: 100%;
  .list-products & {
    @include media-breakpoint-up(sm) {
      display: flex;
    }
  }
  @include media-breakpoint-up(sm) {
    .list-category-products &,
    .list-accessories &,
    .list-recommended-products & {
      display: flex;
      flex: 0 0 calc(50% - calc(#{$global-padding} * .5));
      flex-direction: column;
    }
  }
  @include media-breakpoint-up(md) {
    .list-category-products & {
      flex: 0 0 calc(33% - calc(#{$global-padding} * .5));
    }
  }
  a {
    color: $body-color;
    &:hover {
      text-decoration: none;
    }
  }
  img {
    max-width: 100%;
  }
}

.product-logo {
  margin: $global-margin 0;
  max-width: 160px;
  width: 100%;
}

.product-image {
  a {
    align-items: flex-start;
    display: flex;
    justify-content: center;
  }
  .list-products & {
    flex: 0 0 55%;
    padding: $global-padding;
  }
  .list-category-products &,
  .list-accessories &,
  .list-recommended-products & {
    a {
      padding: $global-padding;
    }
  }
}

.product-description {
  padding: $global-padding;
  .list-products & {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;
    @include media-breakpoint-up(sm) {
      padding-left: 0;
    }
    .product-details {
      flex-grow: 1;
    }
    .product-shoppinginfo {
      flex-direction: row;
      flex-grow: 0;
      justify-content: space-between;
      @include media-breakpoint-down(xs) {
        align-content: baseline;
      }
    }
    a {
      margin-bottom: $global-margin;
      &.btn {
        margin-bottom: 0;
      }
    }
  }
  .list-category-products &,
  .list-accessories &,
  .list-recommended-products & {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(sm) {
      flex-grow: 1;
    }
    .product-shoppinginfo {
      @include media-breakpoint-up(sm) {
        align-content: flex-end;
        flex-grow: 1;
      }
    }
    a {
      display: block;
      margin-bottom: $global-margin;
      &.btn {
        margin-bottom: 0;
      }
    }
  }
  a {
    color: $blue;
    &:hover {
      text-decoration: underline;
    }
  }
  .btn {
    margin-top: 0;
    padding: $global-padding * .5 $global-padding;
    @include media-breakpoint-up(md) {
      padding: $global-padding * .75 $global-padding * 1.25;
    }
  }
}

.product-price {
  align-self: center;
  flex-grow: 1;
  font-size: 1.5em;
  margin: 0;
  padding-right: $global-padding * .5;
  .currency {
    font-size: 80%;
  }
  .list-products & {
    flex-grow: 0;
    padding-right: 0;
    text-align: right;
    @include media-breakpoint-down(xs) {
      text-align: left;
    }
  }
  .list-category-products &,
  .list-accessories &,
  .list-recommended-products & {
    @include media-breakpoint-up(sm) {
      margin: 0;
    }
  }
}

.product-shoppinginfo {
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  @include media-breakpoint-up(sm) {
    align-items: flex-end;
  }
}

.product-name {
  margin: 0 0 $global-margin 0;
  .list-products & {
    font-size: 1.5em;
    margin-top: $global-margin;
  }
}

.product-form,
.product-item,
.product__info__button {
  .btn {
    min-width: 175px;
    padding: 0.75rem 1.5rem;
    position: relative;
    &:after {
      border-color: $white;
      border-radius: 1px;
      border-style: solid;
      border-width: 0 0 4px 4px;
      content: "";
      display: none;
      margin: 0;
      height: 10px;
      left: 80%;
      position: absolute;
      top: 26px;
      transform: rotate(-55deg);
      transform-origin: top left;
      width: 20px;
    }
    &.btn-checked:after {
      animation: confirm .3s ease both;
      animation-play-state: running!important;
      display: block;
    }
  }
}

@keyframes confirm {
  0% {
    width: 0;
    height: 0;
  }
  25% {
    width: 0;
    height: 10px;
  }
  100% {
    width: 20px;
  }
}
