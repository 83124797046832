.login {
  margin-top: $global-padding * 2;
  border-radius: 2px;
  border: none;
  @include media-breakpoint-down(sm) {
    padding-top: 0;
  }
  .col-md-6 {
    text-align: center;
  }
  h3 {
    margin-bottom: $global-margin * 2;
  }
  .btn {
    margin: auto;
    margin-top: $global-margin;
  }
  &__btn-group {
    text-align: center;
    img {
      display: block;
      margin: 0 auto;
   }
  }
  &__register {
    padding: $global-padding * 2;
    &-link {
      img {
        margin: $global-margin / 2 auto $global-margin * 0.9;
        display: block;
        @include media-breakpoint-up(md) {
          &.checkout-pirate {
            margin-bottom: $global-margin * 3.2;
          }
        }
      }
    }
  }
  a.social-btn {
    color: $white;
    text-decoration: none;
  }
  &__form {
    background: $white;
    padding: $global-padding * 2 $global-padding * 5;
    @include media-breakpoint-down(md) {
      padding: $global-padding * 2;
    }
  }
  &__btn-group {
    a {
      margin-top: $global-margin;
      display: block;
    }
  }
  &__forgot-password {
    h2 {
      padding-top: $global-padding * 2;
      text-transform: uppercase;
    }
    p {
      font-size: 1rem;
      padding-top: $global-padding;
    }
    fieldset {
      max-width: 60%;
      margin: auto;
      padding-bottom: $global-padding;
      @include media-breakpoint-down(sm) {
        max-width: 80%;
      }
    }
    .login__btn-group {
      img {
        padding-top: $global-padding * 2;
      }
    }
  }
}
