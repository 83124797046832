.home {
  h1 {
    font-weight: 300;
    text-transform: uppercase;
    @include media-breakpoint-down(sm) {
      font-size: 2.5rem;
    }
  }
  .btn {
    margin-top: $global-margin;
  }
  &--content-wrapper {
     display: table;
     width: 100%;
     height: 100%;
  }
  &--content-inner {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      color: white;
      padding-left: $global-padding;
      padding-right: $global-padding;
  }
  &--content {
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  &--square {
    background-size: cover;
    position: relative;
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }
  &__block1 {
    .home--square {
      background-image: url('../images/block1.jpg');
      @include media-breakpoint-up(md) {
        &:before {
          padding-top: 50%;
        }
      }
    }
  }
  &__block2 {
    margin-top: $global-margin * 2;
    .home--square {
      background-image: url('../images/block2.jpg');
    }
  }
  &__block3 {
    margin-top: $global-margin * 2;
    .home--square {
      background-image: url('../images/block3.jpg');
    }
  }
  &__featured {
    color: $body-color;
    > h2 {
      padding-top: 2rem;
    }
    @include media-breakpoint-down(sm) {
      .btn {
        max-height: 50px;
      }
    }
  }
  &__category-name {
    margin: 0 0 $global-margin 0;
    padding: 0;
  }
}

.sticky {
  margin-top: $global-margin;
  z-index: 5;
}
@include media-breakpoint-up(lg) {
  .home__products-overview {
    flex-basis: 66%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .home {
    display: flex;
    .preview-product-img {
      display: none;
    }
    .checkout-preview__total__subtotal {
      padding-left: $global-padding;
    }
    .checkout-preview__total {
      padding-left: 0;
    }
  }
  .home__checkout-preview {
    flex-basis: 34%;
    max-width: 34%;
    padding-left: $global-padding;
  }
  .sticky {
    margin-top: 90px;
    .home & {
      margin-top: 0;
    }
    height: 100%;
    max-height: calc(100vh - 72px);
    overflow-x: visible;
    overflow-y: auto;
    position: sticky;
    top: 88px;
  }
}

.home__checkout-preview {
  @include media-breakpoint-down(md) {
    display: none;
  }
  .btn {
    &.secondary {
      @include media-breakpoint-up(sm) {
        margin-left: calc(60px - 1rem);
      }
      @include media-breakpoint-up(lg) {
        padding-left: $global-padding;
        margin-left: 0;
      }
    }
  }
  .attention {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
  }
}

html {
  scroll-padding-top: 72px;
}
