.product {
  a {
    color: $blue;
    text-decoration: underline;
  }
  &__info {
    @include media-breakpoint-down(sm) {
      h1 {
        font-size: 2rem;
      }
    }
    h3 {
      text-transform: none;
    }
    &__name {
      font-weight: 600;
      font-family: 'labMedium';
      font-size: 2.5rem;
    }
    &__price {
      font-family: 'labMedium';
      font-weight: bold;
      &__undiscounted {
        display: block;
        text-decoration: line-through;
        .currency {
          color: inherit;
          text-decoration: line-through;
        }
        & + strong{
          color: $red;
        }
      }
      margin-bottom: $global-margin;
    }
    label {
      margin-bottom: $global-margin;
    }
    &__quantity {
      width: 17%;
      float: left;
      @include media-breakpoint-down(sm) {
        width: 47%;
      }
      input {
        height: 54px;
        padding-left: $global-padding;
      }
    }
    &__button {
      width: 80%;
      float: right;
      margin-top: $global-margin * 2.5;
      button.btn {
        height: 54px;
        min-width: 100%;
        padding: 0 $global-padding;
      }
      @include media-breakpoint-down(sm) {
        width: 50%;
        .btn.primary {
          padding: $global-padding;
        }
      }
    }
    td {
      padding-right: $global-padding;
    }
    &__form-error {
      display: block;
      margin-top: -$global-padding / 2;
      margin-bottom: $global-margin;
      .list-accessories & {
        margin-top: $global-margin;
        margin-bottom: 0;
        order: 2;
      }
    }
    &__description {
      font-weight: 300;
      line-height: 1.4;
      margin-top: 2rem;
      b,
      strong {
        font-weight: 600;
      }
      blockquote {
        margin: 1rem 0;
        padding: 0 2rem;
        font-style: italic;
      }
      img {
        max-width: 100%;
      }
    }
  }
  &__gallery {
    margin-bottom: $global-margin;
    .carousel-control {
      &-prev {
        img {
          transform: rotate(180deg);
        }
      }
    }
    .carousel-item {
      flex-flow: column;
      img {
        width: 100%;
      }
    }
    .carousel-indicators {
      position: relative;
      top: 100%;
      width: auto;
      left: 0;
      margin: $global-margin 15%;
      display: block;
      text-align: center;
      z-index: 1;
      li {
        background: $white;
        display: inline;
        text-indent: 0;
        margin: 0;
        img {
          background: $white;
          border: 1px solid $light-gray;
          padding: 10px;
          width: 80px;
        }
        &.active {
          img {
            box-shadow: 0 2px 0 0 $primary;
          }
        }
      }
    }
  }
  &__collections {
    padding-top: $global-padding * 4;
    h2 {
      text-align: center;
      text-transform: uppercase;
    }
  }
  .product__info__rated-note {
    color: inherit;
    display: block;
    margin: $global-margin * 2 0 $global-margin * 3 0;
    text-decoration: none;
    img,
    span {
      margin-right: $global-margin;
      vertical-align: bottom;
    }
  }
}

.product-notices {
  background: $white;
  height: auto;
  margin-top: 1rem;
  padding: $global-padding;
  p:last-of-type {
    margin-bottom: 0rem;
  }
}
