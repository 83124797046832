.custom-alert {
  background-color: $blue !important;
  border: none;
  border-radius: 2px;
  color: $white;
  font-weight: 300;
  margin: $global-margin 0;
  button.close {
    color: $white;
    font-weight: 300;
    &:hover {
      color: $white;
    }
  }
}
