@font-face {
  font-family: 'labMedium';
  src: url('../fonts/LabGrotesque-Medium.woff2') format('woff2'),
       url('../fonts/LabGrotesque-Medium.woff') format('woff'),
       url('../fonts/LabGrotesque-Medium.otf');
}

@font-face {
  font-family: 'labRegular';
  src: url('../fonts/LabGrotesque-Regular.woff2') format('woff2'),
       url('../fonts/LabGrotesque-Regular.woff') format('woff'),
       url('../fonts/LabGrotesque-Regular.otf') format('otf');
}

p {
  font-weight: 300;
}

a {
  color: $blue;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &.link--styled {
    font-size: 0.8rem;
    color: $body-color;
    text-decoration: underline;
    display: block;
  }
  &.link--clean {
    color: $body-color;
    &:hover {
      text-decoration: none;
    }
  }
}

.meta-sku {
  color: $skull-gray;
  font-size: 0.8rem;
}
