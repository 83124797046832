.breadcrumbs {
  font-size: 81.25%;
  font-weight: 400;
  line-height: 1.53846;
  margin: 0;
  min-height: 70px;
  padding: $global-padding * 1.5 0;
  position: relative;
  z-index: 1;
  li {
    display: inline;
    &:not(:first-of-type) {
      margin-left: $global-margin / 3;
    }
    &:not(:last-of-type):after {
      content: "—";
      display: inline-block;
      font-size: 14px;
      margin-left: $global-margin / 3;
    }
    strong {
      color: $body-color;
      font-weight: normal;
    }
    &,
    a {
      color: $generic-light;
      display: inline-block;
    }
  }
}
