.variant-picker {
  margin-bottom: $global-margin;
  .btn-group {
    display: inline-block;
  }
  &__label {
    margin-bottom: $global-margin;

  }
  &__options {
    display: block;
  }
  &__option {
    margin-bottom: 0;
    margin-bottom: $global-margin / 4;
    border-color: $gray;
    float: left;
    &.active {
      border-color: $darken-gray;
    }
    input {
      display: none;
    }
  }
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: $blue;
  color: white;
}
