html {
  background-color: #fff;
}

body {
  background-color: #f5f5f5;
}

body.lock {
  overflow: hidden;
}

.container {
  max-width: 1278px;
}
