.navigation {
  ul {
    margin-bottom: $global-margin;
    display: block;
    text-align: left;
    &.no-border {
      border-bottom: 0;
      margin-bottom: 0;
    }
    @include media-breakpoint-down(sm) {
      border-bottom: 0;
    }
    .nav-item {
      display: inline;
      font-family: 'labRegular', sans-serif;
      + .nav-item {
        margin-left: 0;
      }
      a {
        color: $nav-color;
        transition: 0.3s;
        display: inline-block;
        &:hover {
          text-decoration: none;
          color: $body-color;
        }
      }

      @include media-breakpoint-up(md) {
        &__dropdown {
          display: inline-block;
          position: relative;

          &:hover > a {
            border-bottom: 3px solid black;
          }

          & > a {
            padding: 0.5rem;
            margin: 1rem;
            display: inline-block;
            box-sizing: border-box;
            border-bottom: 3px solid $white;
          }
        }

        &__dropdown-content {
          display: none;
          position: absolute;
          z-index: 3;
          border: solid 1px $gray;
          background-color: $white;
          text-align: left;

          a {
            padding: 0;
            font-size: initial;
            text-transform: none;
            text-decoration: none;
          }

          .container {
            padding: $global-padding;
            max-height: 28rem;

            > ul {
              min-width: 120px;
              width: 360px;
              display: flex;
              flex-wrap: wrap;

              li {
                display: inline-block;
                max-width: 110px;

                &:not(:last-child) {
                  margin-right: $global-padding * 2;
                }
              }
            }
          }
        }

        &:hover .nav-item__dropdown-content {
          display: block;
        }
      }
    }
  }
  label {
    margin-bottom: 0px;
  }
}

.menu {
  .menu__item {
    ul {
      border-bottom: none;
      list-style-type: none;
      padding-left: 0;
      text-align: left;
      margin-bottom: 0;

      ul {
        padding-left: $global-padding;
      }

      li {
        text-align: left;
      }
    }

    a.active {
      text-decoration: underline;
    }

    a.active:hover {
      text-decoration: underline;
    }

    @include media-breakpoint-down(sm) {
      .nav-item__submenu ul {
        text-align: center;
        font-size: 0.8rem;

        ul {
          padding-left: 0;
          font-size: 0.7rem;
        }

        li {
          text-align: center;
        }
      }
    }
  }
}

.navbar {
  margin: 0;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 8;
  @include media-breakpoint-down(sm) {
    > .container-fluid {
      padding: 0;
    }
    svg {
      max-height: 30px;
      width: auto;
    }
  }
  @media (max-width: 370px) {
    svg {
      max-height: 23px;
      width: auto;
    }
  }
  &__login {
    background: $green;
    color: $white;
    p {
      font-size: 0.7rem;
      margin-bottom: 0;
      color: $skull-gray;
      line-height: 1.9rem;
      width: 100%;
    }
    ul {
      list-style: none;
      margin-bottom: 0;
      li {
        display: inline-block;
        padding: 0 $global-padding / 2;
        line-height: 1.9rem;
        a {
          color: $white;
          font-size: 0.7rem;
        }
      }
    }
  }
  &__logo {
    padding: 0;
    display: flex;
    align-items: center;
    svg {
      display: block;
      max-height: 53px;
      max-width: 53px;
    }
  }
  &__search {
    padding: $global-padding 0;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    form {
      svg {
        width: 18px;
        height: 18px;
        margin-left: 10px;
        vertical-align: middle;
      }
      .btn {
        padding: $global-padding;
      }
    }
    .mobile-search-icon {
      float: right;
      padding-right: $global-padding;
    }
  }
  &__brand {
    background-color: $white;
    @include media-breakpoint-down(sm) {
      background-color: $white;
      left: 0;
      top: 0;
      width: 100vw;
      z-index: 5;
    }
    .static {
      position: static;
    }
    &__checkout {
      z-index: 4;
      line-height: 2.4rem;
      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: row-reverse;
      }
      a {
        color: $body-color;
      }
      a:hover {
        text-decoration: none;
      }
      .hamburger__icon {
        display: inline-block;
        padding: 1rem .5rem 1rem 0rem;
      }
      .checkout__icon {
        border-top: 1px solid transparent;
        border-radius: 4px 4px 0 0;
        display: inline-block;
        padding: 1rem 1.5rem 1rem 0rem;
        position: relative;
        right: 0;
        top: 0;
        white-space: nowrap;
        z-index: 4;
        .checkout-label {
          display: none;
          @include media-breakpoint-up(md) {
            display: inline-block;
            font-family: 'labRegular', sans-serif;
            font-size: 16px;
            padding-right: $global-padding / 2;
            position: relative;
            &::before {
              background-color: #e11a27;
              border-radius: 1px;
              bottom: 0;
              content: "";
              display: block;
              height: 3px;
              left: 0;
              position: absolute;
              right: 0.5rem;
            }
          }
        }
        .checkout-cart {
          margin-right: $global-padding / 4;
        }
        .navbar__brand__checkout__icon {
          display: inline-block;
          svg {
            vertical-align: middle;
          }
        }
        .badge {
          background-color: $red;
          border-radius: 10rem;
          color: #fff;
          font-family: sans-serif;
          font-size: 11px;
          font-weight: bold;
          line-height: 17px;
          min-width: 18px;
          padding: 1px 3px 0 3px;
          position: absolute;
          right: 18px;
          text-align: center;
          top: 18px;
          &.empty {
            background-color: $darken-gray;
          }
        }
      }
    }
  }
  &__menu {
    @include media-breakpoint-down(sm) {
      .navigation {
        position: fixed;
        left: -180px;
        top: 75px;
        height: 100vh;
        width: 180px;
        z-index: 4;
        background-color: $white;
        padding-left: 0;
        padding-right: $global-padding;
        padding-top: $global-padding * 2;
        transition: 0.3s ease-in-out;
        ul {
          text-align: left;
          .nav-item {
            width: 100%;
            text-align: left;
            a {
              padding: $global-padding / 2 $global-padding;
              font-size: 1rem;
              display: block;
            }
          }
        }
        &.open {
          left: 0;
          box-shadow: 1px 1px 10px 1px $gray;
        }
      }
    }
  }
}

.nav-dropdown {
  position: relative;
}

.nav-dropdown > input[type="checkbox"] {
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  left: -200vw !important;
  overflow: hidden !important;
  position: fixed !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.nav-dropdown > input[type="checkbox"]:checked ~ .nav-dropdown-content {
  display: block;
}

.nav-dropdown-toggle {
  background: none;
  border: none;
  color: $nav-color;
  cursor: pointer;
  font-family: 'labRegular', sans-serif;
  font-weight: 300;
  padding: $global-padding / 2;
}
@include media-breakpoint-down(lg) {
  .nav-dropdown-toggle[for="language-toggle"] span {
    display: none;
  }
}

.nav-dropdown > input[type="checkbox"]:focus + .nav-dropdown-toggle {
  outline: 1px solid var(--primary);
}

.nav-dropdown-toggle svg {
  margin-left: 2px;
  vertical-align: text-bottom;
}

.nav-dropdown-content {
  background: $white;
  display: none;
  margin-top: 0;
  min-width: 160px;
  position: absolute;
  right: 0;
  z-index: 2;
}

.navigation ul .nav-item .nav-dropdown-content a {
  display: block;
  padding: $global-padding / 2 $global-padding;
  text-align: right;
}

.nav-dropdown-content ul {
  padding: $global-padding;
}

.nav-dropdown-content ul li {
  margin: 0;
}

.mobile-menu {
  margin: 0;
  padding: 24px 0px;
  background-color: white;
  border-radius: 2px;
  overflow: scroll;
  list-style-type: none;
}

.mobile-menu > .menu {
  padding-inline-start: 24px;
}

.mobile-menu .menu__item {
  list-style-type: none;
}

.mobile-menu > li {
  background-color: white;
  padding: 0px;
  margin: 0px 16px !important;
}

.mobile-menu > li:not(:last-child) {
  border-bottom: solid 1px #f5f5f5;
}

.mobile-menu a,
.mobile-menu strong,
.mobile-language-menu-icon {
  color: black;
  display: block;
  font-family: 'labRegular', sans-serif;
  font-weight: 300;
  height: 100%;
  padding: 16px 16px;
  text-decoration: none;
  width: 100%;
}

.mobile-menu strong {
  text-decoration: underline;
}

.mobile-container {
  z-index: 30;
  position: fixed;
  top: 4.5rem;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  animation-duration: .2s;
  padding: 16px;
  overflow: scroll;
}

.mobile-language-menu ul {
  list-style-type: none;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0px;
}
.mobile-language-menu ul li {
  margin: 0px;
}
