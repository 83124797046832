/* hide content that is part of the other group */
html.group-a .group-b,
html.group-b .group-a,
/* content should only be shown if their corresponding group class is set on the root node */
html:not(.group-a) .group-a,
html:not(.group-b) .group-b,
/* hide fallback content if either group A or B is set on the root node */
html.group-a .group-none,
html.group-b .group-none {
  display: none !important;
}
