/* cookie banner */

.cookie-monster {
  background: $white;
  bottom: 2rem;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  max-height: calc(100vh - 4rem);
  overflow: auto;
  padding: 0 2rem 2rem 2rem;
  position: absolute;
  position: fixed;
  right: 2rem;
  transition: opacity ease .3s;
  width: 460px;
  z-index: 10000;
}

@media (max-width: 768px) {
  .cookie-monster {
    bottom: 0;
    left: 0;
    max-height: 100vh;
    padding: 0 1rem 3rem 1rem;
    right: 0;
    width: 100%;
    font-size: 14px;
  }
}

.cookie-title {
  font-family: 'labLight', sans-serif;
  font-size: 28px;
  line-height: 1.4;
  margin: 0 0 8px 0;
  padding-top: 2rem;
}

.cookie-description {
  color: $generic;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 300;

  p {
    margin: 0 0 24px 0;
  }
}

.cookie-monster a {
  text-decoration: underline;
}

.cookie-settings {
  display: none;

  ul {
    padding: 0;
    margin: 0 0 16px 0;
    list-style-type: none;
  }

  li {
    margin-bottom: 8px;
  }

  strong {
    display: block;
    position: relative;
  }
}

.cookie-on strong::after {
  border: solid $success;
  border-width: 0px 2px 2px 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  margin: 0 0 0 1em;
  position: relative;
  top: -.35em;
  transform: scale(2) rotate(45deg);
  transition: transform .2s ease-out;
  width: 0.23em;
}

.cookie-off strong::after {
  background: linear-gradient(to bottom, transparent 40%, #d00 40%, #d00 60%, transparent 60%), linear-gradient(to right, transparent 40%, #d00 40%, #d00 60%, transparent 60%);
  content: "";
  display: inline-block;
  height: 16px;
  margin: -0.15em 0 0 .5em;
  position: relative;
  top: .15em;
  transform: rotate(45deg);
  transition: transform .2s ease-out;
  width: 16px;
}

.cookie-control button {
  background: none;
  border-color: $blue;
  border-style: dotted;
  border-width: 0 0 2px 0;
  color: $blue;
  cursor: pointer;
  display: inline-block;
  font-family: sans-serif;
  font-size: $defaultFontSize;
  font-weight: 300;
  padding: 0;

  &:focus {
    border-bottom-color: $blue-light;
    color: $blue-light;
    outline: none;
  }
}

.cookie-readonly {
  strong::after {
    border-color: $generic-light;
  }

  button {
    border-color: $generic-light;
    border-width: 0 0 2px 0;
    color: $generic-light;
    cursor: default;
  }
}

.cookie-footer {
  align-items: center;
  display: flex;
}

.cookie-monster .button {
  min-width: calc(165px * .5);
  height: 42px;
  line-height: 42px;
  padding: 0 16px;
}

.cookie-info {
  color: $generic-light;
  font-size: $defaultFontSizeSmall;
  font-weight: 300;
  display: inline-block;
  line-height: 1.4;
  padding: 0 16px;
}

.cookie-close {
  background: none;
  border: none;
  color: #6b6b6b;
  font-size: 1rem;
  line-height: 1;
  padding: 16px;
  position: absolute;
  right: .35rem;
  top: .35rem;
  height: 42px;
  line-height: 42px;
  padding: 0 16px;
}

.cookie-monster--show-settings {
  .cookie-settings {
    display: block;
  }

  .cookie-description {
    display: none;
  }
}

.open-cookie-monster button {
  background: none;
  border: none;
  color: $generic-light;
  cursor: pointer;
  line-height: 1.4;
  font-family: sans-serif;
  font-size: $defaultFontSize;
  font-weight: 300;
  padding: 0;

  &:focus, &:hover {
    color: $white;
    outline: none;
  }
}
