@import "variables";
@import "~bootstrap/scss/bootstrap";

@import "components/banner";
@import "components/breadcrumbs";
@import "components/buttons";
@import "components/cards";
@import "components/footer";
@import "components/forms";
@import "components/header";
@import "components/labels";
@import "components/loader";
@import "components/pagination";
@import "components/table";
@import "components/tabs";
@import "components/typography";
@import "components/variant-picker";
@import "components/alert";
@import "components/cookie_monster";

@import "layouts/page";
@import "layouts/account";
@import "layouts/checkout";
@import "layouts/home";
@import "layouts/login";
@import "layouts/no-results";
@import "layouts/order-details";
@import "layouts/payment";
@import "layouts/product-list";
@import "layouts/product-page";
@import "layouts/continue";
@import "layouts/ab-test";
// TODO: Styles are removed to reduce the size in production
// However, templates/style-guide.html is still there.
// Remove the next line when the template is removed or fix the guide.
// @import "layouts/styleguide";
