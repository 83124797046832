.checkout {
  display: flex;
  margin-bottom: $global-margin * 5;
  &__review-container {
    margin-bottom: $global-margin;
  }
  @include media-breakpoint-down(md) {
    flex-direction: column;
    &__shipping-container {
      order: 2;
    }
  }
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    &__shipping-container {
      flex-basis: 66%;
      flex-grow: 0;
      flex-shrink: 0;
      padding-top: $global-padding * 2;
    }
    &__review-container {
      flex-basis: 34%;
      max-width: 34%;
      padding-left: 1rem;
    }
  }
  h2,
  h3 {
    margin: 0 0 $global-margin * 2 0;
    min-height: 26px;
  }
  .product-name {
    margin: 0 0 0.5rem 0;
  }
  .primary {
    margin-top: $global-margin;
  }
  &__edit-link {
    margin-top: $global-margin;
  }
  &__header {
    background-color: $light-gray;
    padding: $global-padding * 1.5 0 $global-padding;
    margin-bottom: 50px;
    @include media-breakpoint-down(sm) {
      padding: $global-padding 0;
      & ~ .maincontent {
        margin-top: 0;
      }
    }
    a {
      display: inherit;
    }
    a:hover {
      text-decoration: none;
    }
    svg {
      vertical-align: baseline;
      margin-right: 30px;
      width: 113px;
      height: auto;
      @include media-breakpoint-down(sm) {
        max-height: 34px;
        vertical-align: text-bottom;
        margin-right: 10px;
      }
    }
    h1 {
      display: inline-block;
      font-size: 40px;
      font-family: 'labMedium';
      margin: 0;
      @include media-breakpoint-down(sm) {
        font-size: 1.8rem;
        margin-bottom: 0;
      }
    }
    .container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  &__login {
    margin: $global-margin * 5 0 $global-margin * 3;
    border: 1px solid $gray;
    border-radius: $button-border-radius;
    text-align: center;
    h3 {
      margin-bottom: $global-margin * 3;
    }
    .btn {
      margin: $global-margin * 2 auto 0;
    }
    &__continue {
      padding: $global-padding * 2 0 $global-padding;
    }
    &__form {
      background-color: $light-gray;
      padding: $global-padding * 2 $global-padding * 6 $global-padding * 2;
      @include media-breakpoint-down(md) {
        padding: $global-padding * 2;
      }
      .form-group {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  &__review {
    background-color: $white;
    margin-bottom: $global-margin;
    padding: $global-padding;
    .note {
      color: $skull-gray;
      font-size: $defaultFontSizeSmall;
      @include media-breakpoint-up(lg) {
        margin-top: 1rem;
      }
    }
    hr {
      background-color: $gray;
      margin: $global-margin 0;
    }
    h3 {
      border-bottom: 1px solid $gray;
      font-size: 80%;
      font-weight: 400;
      line-height: 1.5;
      margin: 0 0 $global-margin 0;
      padding-bottom: $global-padding * .5;
    }
    &__section {
      .voucher-add-form {
        display: block;
      }
      .voucher-remove-form {
        display: inline-block;
      }
      .input-btn {
        input {
          border-radius: 2px;
          box-shadow: inset 0 1px 2px rgb(0, 0, 21%);
        }
        .btn {
          height: calc(2.25rem + 2px);
          padding: $global-padding / 2;
        }
        .btn-voucher-remove {
          position: relative;
        }
      }
      input {
        padding: $global-padding;
      }
    }
    &__promocode {
      margin-bottom: $global-margin * .5;
      margin-top: $global-margin * .5;
      form {
        display: inline-block;
      }
    }
    &__tittle {
      margin-bottom: $global-margin * 2;
    }
    &__variant {
      color: $skull-gray;
    }
    .currency {
      color: $generic-light;
      font-weight: normal;
      padding-right: $global-padding * .25;
    }
  }
  &__thankyoupage {
    text-align: center;
    margin-top: $global-margin * 6;
    @include media-breakpoint-down(sm) {
      margin-top: $global-margin * 3;
    }
    h3 {
      margin-bottom: $global-margin * 3;
    }
    .btn {
      margin-top: $global-margin;
    }
    span {
      color: $darken-gray;
    }
    h1 {
      font-weight: 300;
      margin-top: -$global-margin * 4;
    }
    &__form {
      margin-top: $global-margin * 6;
      margin-bottom: $global-margin * 3;
      border: 1px solid $light-gray;
      border-radius: $button-border-radius;
      @include media-breakpoint-down(sm) {
        margin-top: $global-margin * 3;
      }
      .col-md-6 {
        padding: $global-padding * 3 0 $global-padding * 2;
        &:first-of-type {
          background-color: $light-gray;
          padding: $global-padding * 3 $global-padding * 4 $global-padding * 2;
          @include media-breakpoint-down(md) {
            padding: $global-padding * 3 $global-padding * 2 $global-padding * 2;
          }
        }
      }
      img {
        margin-bottom: $global-margin * 1.2;
        @include media-breakpoint-only(md) {
          margin-bottom: $global-margin * 2.4;
        }
      }
    }
  }
  &__new-address {
    grid-column-end: span 2;
    @include media-breakpoint-down(md) {
      grid-column-end: span 1;
    }
    &-container {
      grid-column-end: span 2;
      @include media-breakpoint-down(md) {
        grid-column-end: span 1;
      }
    }
  }
  &__shipping {
    .btn {
      grid-column-start: 2;
      width: 100%;
      @include media-breakpoint-down(md) {
        grid-column-start: 1;
      }
    }
  }
  &__shipping-methods {
    p {
      margin: $global-margin * 2 0;
    }
    .address {
      width: calc(50% - #{$global-margin});
    }
    .btn {
      grid-column-start: 2;
      width: 100%;
      @include media-breakpoint-down(md) {
        grid-column-start: 1;
      }
    }
    &-form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: $global-margin * 2;
      grid-row-gap: $global-margin;
      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
      }
      label {
        cursor: pointer;
        display: block;
        margin: 0;
      }
      .btn {
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
    }
  }
  &__payment {
    h3 {
      margin: $global-margin * 2 0 $global-margin;
    }
    &-form {
      label {
        cursor: pointer;
        display: block;
        margin: 0;
      }
      .btn {
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
      &__choice {
        margin-bottom: $global-margin;
      }
    }
  }
  &__payments {
    &-card-expiry-slash {
      pointer-events: none;
      font-size: 1.5rem;
      font-weight: 100;
    }
  }
  &__addresses {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $global-margin * 2;
    &--full-width {
      grid-template-columns: 1fr;
    }
    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
    }
    &-panel {
      label {
        cursor: pointer;
        display: block;
        margin-right: 0;
        > * {
          border-radius: 2px;
        }
      }
    }
  }
  &__billing {
    &-form {
      p {
        margin: $global-margin * 2 0;
      }
    }
  }
}

.hidden {
  display: none
}

.attention {
  padding: 1rem;
  background-color: $white;
  margin-bottom: 1rem;
  p {
    margin: 0;
  }
}

.checkout-preview {
  background: $white;
  padding: $global-padding;
  @include media-breakpoint-only(sm) {
    padding: $global-padding;
  }
  h3 {
    color: $skull-gray;
  }
  &__line {
    &__product {
      a {
        transition: 0.3s;
        &:hover {
          color: $skull-gray;
        }
        p {
          display: inline-block;
          margin-top: $global-margin / 2;
          margin-bottom: 0;
          line-height: 1rem;
          small {
            color: $skull-gray;
          }
        }
        img {
          vertical-align: top;
          margin-right: $global-margin;
        }
      }
    }
    &__quantity {
      @include media-breakpoint-down(sm) {
        margin-bottom: $global-margin / 2;
      }
      form {
        display: inline-block;
        input {
          width: 62px;
          margin-right: 10px;
        }
      }
      &-error {
        display: block;
        white-space: normal;
      }
      img {
        max-width: 18px;
      }
    }
  }
  &__total {
    padding-top: $global-padding;
    margin-bottom: $global-margin;
    p {
      margin-bottom: 0;
    }
    &__subtotal {
      color: $body-color;
      @include media-breakpoint-up(sm) {
        padding-left: calc(60px + #{$global-padding} * 2);
      }
      &--shipping {
        color: $body-color;
      }
    }
    @include media-breakpoint-up(sm) {
      padding-left: calc(60px + #{$global-padding} * 1);
    }
  }
  &__delivery-info {
    p {
      margin-bottom: 0;
      display: inline-block;
    }
  }
  &__empty {
    text-align: center;
    img {
      margin: $global-margin * 2 0;
    }
    .btn {
      margin: $global-margin * 2 0 $global-margin * 5;
    }
  }
  &__submit {
    @media (max-width: 370px) {
      width: 100%;
    }
  }
  &__clear {
    @media (max-width: 400px) {
      margin-top: $global-margin;
    }
    @media (min-width: 470px) {
      margin-right: $global-margin;
    }
    @media (max-width: 370px) {
      width: 100%;
    }
    margin-right: $global-margin;
  }
  &-item-delete {
    cursor: pointer;
    display: inline-block;
    margin-left: $global-margin / 2;
    svg {
      vertical-align: text-top;
    }
  }
}

.stripe-button-el {
  border: 1px solid $blue !important;
  transition: 0.3s;
  border-radius: $button-border-radius !important;
  cursor: pointer;
  white-space: normal;
  padding: $global-padding $global-padding * 2 !important;
  background-color: $blue !important;
  background-image: none !important;
  // -webkit-font-smoothing: auto !important;
  box-shadow: none !important;
  span {
    padding: 0 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    height: auto !important;
    line-height: 1 !important;
    min-height: 0 !important;
    background-image: none !important;
    background-color: transparent !important;
    background: transparent !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    font-family: $font-family-sans-serif !important;
  }
}

.shipping-info {
  dd {
    margin-bottom: 0;
  }
  dt {
    margin-top: 1.5rem;
  }
}

.checkout-preview__empty-cart {
  background-color: $white;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='48' height='48' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.792 2H1v2h3.218l2.77 12.678H7V17h13v-.248l2.193-9.661L22.531 6H6.655l-.57-2.611L5.792 2zm14.195 6H7.092l1.529 7h9.777l1.589-7z' fill='%23d3d1d0'/%3E%3Cpath d='M10 22a2 2 0 100-4 2 2 0 000 4zm9-2a2 2 0 11-4 0 2 2 0 014 0z' fill='%23d3d1d0'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  color: $gray;
  padding: $global-padding;
  position: relative;

  p {
    padding-top: 7rem;
    text-align: center;
  }
  .home & {
    @include media-breakpoint-up(lg) {
      padding: 0 0 100% 0;
      p {
        padding: calc(50% + calc(2 * #{$global-padding})) $global-padding 0 $global-padding;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
      }
    }
  }
}

.checkout-preview {
  color: $black;
  @include media-breakpoint-up(md) {
    display: block;
  }
  small {
    color: $black;
  }
  .table__header small {
    display: block;
  }
  strong {
    color: $body-color;
  }
}
.checkout-preview__line__product {
  margin-top: $global-margin * .25;
  a {
    display: flex;
    p {
      margin-top: $global-margin * .5;
    }
    @include media-breakpoint-down(xs) {
      img {
        display: none;
      }
    }
  }
}
.checkout-preview__header__productinfo,
.checkout-preview__line__productinfo {
  padding-left: 0;
}
.checkout-preview__line__quantity {
  margin-bottom: 0;
  white-space: nowrap;
  form input {
    height: inherit;
    margin-right: 0;
  }
}
.checkout-preview-item-delete {
  line-height: 1;
  margin-left: 0;
  opacity: .5;
  transition: opacity 200ms ease-in-out;
  @media (hover: none) {
    opacity: 1;
  }
  padding: $global-padding * .5 $global-padding * .25;
  position: relative;
  vertical-align: top;
  z-index: 1;
  .btn-voucher-remove & {
    padding: 0 $global-padding * .5;
  }
}
.checkout-preview__line {
  border-bottom: none;
  padding-bottom: 0;
}
.checkout-preview__line:hover {
  .checkout-preview-item-delete {
    opacity: .75;
    &:hover {
      opacity: 1;
    }
  }
}
.checkout-preview-item-price {
  margin-top: $global-margin * .5;
}
.checkout-item-price,
.checkout-item-quantity {
  padding-left: 0;
}

.currency {
  color: $generic-light;
  font-size: 90%;
  font-weight: normal;
  &::after {
    content: " ";
    display: inline;
  }
}
small .currency {
  display: inline-block;
}

.deliveryform select {
  margin: $global-margin * .5 0;
  padding: 0 $global-padding * .5;
  width: 100%;
  @include media-breakpoint-only(md) {
    .home & {
      margin: 0;
    }
  }
  @include media-breakpoint-only(sm) {
    margin-bottom: 0;
  }
}
.checkout-preview__delivery-info {
  border-bottom: none;
  padding-bottom: 0;
  p {
    margin: 0;
    @include media-breakpoint-only(md) {
      .home & {
        line-height: calc(2.25rem + 2px);
      }
    }
  }
}

.checkout-preview__total {
  small {
    border-top: 1px solid $gray;
    color: $generic-light;
    display: block;
    margin-top: $global-margin * .25;
    padding-top: $global-padding * .25;
  }
}
.checkout-preview__checkout {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  .btn {
    margin-right: 0;
    margin-top: $global-margin;
    padding-left: $global-padding * 2;
    padding-right: $global-padding * 2;
    &.secondary {
      border-color: transparent;
      margin-left: -1rem;
      @include media-breakpoint-up(sm) {
        margin-left: calc(60px - 1rem);
      }
    }
  }
}

.checkout-notices {
  @include media-breakpoint-down(md) {
    display: none;
  }
  background: $white;
  padding: $global-padding;
  margin-top: 1rem;
  p {
    font-size: $defaultFontSizeSmall;
    color: $skull-gray;
  }
  p:last-of-type {
    margin-bottom: 0rem;
  }
}

.checkout-business-order {
  background: $white;
  padding: $global-padding;
  margin-top: 1rem;
  p {
    font-size: $defaultFontSizeSmall;
    color: $skull-gray;
  }
  p:last-of-type {
    margin-bottom: 0rem;
  }
}


.checkout-notices-reviews {
  a {
    align-items: center;
    color: inherit;
    display: flex;
    justify-content: center;
    text-decoration: none;
  }
  img {
    margin-right: $global-margin * .5;
  }
}

.mobile-hide {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

#id_discount-voucher {
  text-transform: uppercase;
}

@include media-breakpoint-down(md) {
  .js-collapse-checkout-review {
    .checkout__review {
      h3 {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' aria-labelledby='chevron down' color='%23777'%3E%3Cdefs/%3E%3Cpolyline points='8 10 12 14 16 10'/%3E%3C/svg%3E%0A");
        background-position: 100%;
        background-repeat: no-repeat;
        border-bottom: none;
        margin: 0;
        padding: 0;
      }
      hr,
      .row {
        display: none;
      }
    }
  }
}
